import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Link, Tooltip } from "@mui/material";
import Axios from "axios";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { forgotPasswordApi, updatePasswordApi } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";

const ForgetPassword = ({ openForgotPassword, setForgotPassword }) => {
  ForgetPassword.propTypes = {
    openForgotPassword: PropTypes.bool.isRequired,
    setForgotPassword: PropTypes.func.isRequired,
  };
  const [otp, setOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(60);
  const [disableResendOtp, setDisableResendOtp] = useState(false);
  const [showCounter, setShowCounter] = useState(false);

  let intervalId;

  let validationSchema = yup.object().shape({
    username: yup.string().trim().required("Please Enter Username"),
    password:
      otp &&
      yup
        .string()
        .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, "Password atleast have 8 character containing one special character, Uppercase, Lowercase and Digit")
        .required("Password is required"),
    authCode: otp && yup.string().trim().required("Please Enter OTP"),
    confirmNewPassword:
      otp &&
      yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Confirm Password"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      authCode: "",
      password: "",
      confirmNewPassword: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const uName = formik.values.username;
  const authCode = formik.values.authCode;

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger mb-2">{formik.errors[field]}</div>;
  };

  const handleSubmit = async (values) => {
    if (!otp) {
      setLoading(true);
      await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${forgotPasswordApi}?username=${encodeURIComponent(uName)}`)
        .then((response) => {
          setOtp(true);
          setDisableResendOtp(true);
          setShowCounter(true);
          toast.success(<IntlMessages id={"message.changePassword.sendOtp.success"} />);
          setLoading(false);
        })
        .catch((errors) => {
          toast.error(errors.response.data.message);
          setLoading(false);
        });
      setLoading(false);
    } else {
      setLoading(true);
      await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${updatePasswordApi}?authCode=${encodeURIComponent(authCode)}`, {
        username: values.username.trim(),
        password: values.password.trim(),
      })
        .then((response) => {
          toast.success(<IntlMessages id={"button.text.passwordChangedSuccess.success"} />);
          setForgotPassword(false);
        })
        .catch((errors) => {
          toast.error(errors.response.data.message);
        });
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    setLoading(true);
    setShowCounter(false);
    await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${forgotPasswordApi}?username=${encodeURIComponent(uName)}`)
      .then((response) => {
        setLoading(false);
        toast.success(<IntlMessages id={"message.changePassword.sendOtp.success"} />);
        setDisableResendOtp(true);
        setShowCounter(true);
      })
      .catch((errors) => {
        toast.error(errors.response.data.message);
        setLoading(false);
      });
    setLoading(false);
  };

  let count = 60;
  function startCounter() {
    intervalId = setInterval(() => {
      if (count == 1) {
        setDisableResendOtp(false);
        setShowCounter(false);
        setCounter(60);
        clearInterval(intervalId);
      }
      if (otp) {
        setCounter((p) => p - 1);
        count--;
      }
    }, 1000);
  }

  useEffect(() => {
    if (showCounter) {
      startCounter();
    }
  }, [showCounter]);

  return (
    <>
      <ModelSelfFooter
        open={openForgotPassword}
        heading={<IntlMessages id="forgotPassword.heading" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="large"
              onClick={() => setForgotPassword(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setForgotPassword(false)}
        maxWidth="md"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          className="mdForm forgotPassword"
          onSubmit={formik.handleSubmit}>
          <Row className="mb-2 mt-4">
            <Col
              xs={5}
              className="labelName">
              <Label className="fontSize requiredField">Your Email</Label>
            </Col>
            <Col
              xs={7}
              className="removeOutline">
              <FormGroup>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  onChange={formik.handleChange}
                  value={formik?.values?.username.trim()}
                  disabled={otp}
                />
                {renderErrorMessage("username")}
              </FormGroup>
            </Col>
          </Row>
          {otp && (
            <>
              <Row className="mb-2">
                <Col
                  xs={3}
                  className="labelName">
                  <Label className="fontSize requiredField">OTP</Label>
                </Col>
                <Col></Col>
                <Col
                  xs={7}
                  className="removeOutline">
                  <FormGroup>
                    <Input
                      clssName="boxHeight"
                      type="number"
                      name="authCode"
                      id="authCode"
                      onChange={formik.handleChange}
                      value={formik.values.authCode}
                      floatingLabelText="otp"
                    />
                    {renderErrorMessage("authCode")}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col></Col>
                <Col xs={7}>
                  <div className="counterArea">
                    {showCounter && <span className="counter-span">{counter}</span>}
                    {loading ? (
                      <Button
                        size="sm"
                        className="resendButton"
                        disabled>
                        <CircularProgress
                          color="secondary"
                          size={"15px"}
                        />
                      </Button>
                    ) : (
                      <Link
                        component="button"
                        disabled={loading || disableResendOtp}
                        onClick={() => resendOTP()}
                        className={disableResendOtp ? "disableResetButton" : "resendButton"}>
                        Resend OTP
                      </Link>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col
                  xs={5}
                  className="labelName">
                  <Label className="fontSize requiredField">New Password</Label>
                </Col>
                <Col
                  xs={7}
                  className="removeOutline">
                  <FormGroup>
                    <Input
                      name="password"
                      id="password"
                      onChange={formik.handleChange}
                      value={formik.values.password.trim()}
                      floatingLabelText="Enter your New Password"
                      type="password"
                    />
                    {renderErrorMessage("password")}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col
                  xs={5}
                  className="labelName">
                  <Label className="fontSize requiredField">Confirm Password</Label>
                </Col>
                <Col
                  xs={7}
                  className="removeOutline">
                  <FormGroup>
                    <Input
                      name="confirmNewPassword"
                      onChange={formik.handleChange}
                      value={formik.values.confirmNewPassword.trim()}
                      floatingLabelText="Enter your Confirm New Password"
                      type="password"
                    />
                    {renderErrorMessage("confirmNewPassword")}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setForgotPassword(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="buttonSpacing"
              type="submit"
              disabled={loading}>
              {loading && (
                <>
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                  <IntlMessages id={!otp ? "forgotPassword.button" : `button.text.updatePassword`} />
                </>
              )}
              {!loading && <IntlMessages id={!otp ? "forgotPassword.button" : `button.text.updatePassword`} />}
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};

export default ForgetPassword;
