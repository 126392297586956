import Axios from "axios";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { BiLockAlt, BiUser, BiShow, BiHide } from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Row } from "reactstrap";
import * as yup from "yup";
import hrmsHostLogo250by250 from "../../assets/images/hrmshost-logo-250x250.png";
import { loginCompany } from "../../redux/ApiLists";
import Copyright from "../shared/copyright/Copyright";
import ForgetPassword from "./ForgetPassword";
import { setCookies } from "./HandleCookies";

export default function SignIn() {
  const [, setAnchorEl] = useState();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [openForgotPassword, setForgotPassword] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const [showPassword, setShowPassword] = useState(false);

  let validationSchema = yup.object().shape({
    username: yup.string().trim().required("Please Enter Username"),
    password: yup.string().trim().required("Please Enter Password"),
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      setIsFormSubmitted(true);
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const recaptchaInstance = useRef(null);

  const verifyCallback = function (response) {
    if (response) {
      setCaptchaToken(response);
      setVerified(true);
    }
  };

  const expireCallback = function (response) {
    setVerified(false);
  };

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onForgotPasswordClick = () => {
    setForgotPassword(true);
    handleClose();
  };

  const handleSubmit = async (values) => {
    if (!verified) {
      return;
    }
    setLoading(true);
    await Axios.post(
      `${process.env.REACT_APP_ERMS_ADMIN_API}${loginCompany}?token=${captchaToken}`,
      { username: values.username.trim(), password: values.password.trim() },
      {
        headers: {
          app_id: process.env.REACT_APP_APPLICATION_ID,
        },
      }
    )
      .then((response) => {
        if (response) {
          setCookies(response.data.data);
          sessionStorage.setItem("token", response.data.data.token.access_token);
          sessionStorage.setItem("profile", JSON.stringify(response.data.data.profile));
          sessionStorage.setItem("company_code", response.data.data.profile.attributes.company_code);
          toast.success("Welcome " + response.data.data.profile.firstName + "!");
          setTimeout(function () {
            window.location.replace("/dashboard");
          }, 1000);
        } else {
          toast.error("Invalid response");
          recaptchaInstance.current.reset();
          setVerified(false);
        }
      })
      .catch((err) => {
        toast.error("Invalid Username or Password");
        recaptchaInstance.current.reset();
        setVerified(false);
      });
    setLoading(false);
    recaptchaInstance.current.reset();
    setVerified(false);
    setIsFormSubmitted(false);
  };

  return (
    <>
      <div className="login-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-section">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={hrmsHostLogo250by250}
                      alt="Brand Logo"
                    />
                  </Link>
                </div>
                <div className="text-center mb-5">
                  <h1>Employee Sign in</h1>
                </div>
                <div className="login-inner-form">
                  <Form
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onSubmit={formik.handleSubmit}>
                    <div className="login-inner-form">
                      <FormGroup>
                        <Input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          name="username"
                          value={formik.values.username.trim()}
                          placeholder="Your Email"
                          onChange={formik.handleChange}
                        />
                        <BiUser />
                        {renderErrorMessage("username")}
                      </FormGroup>

                      <FormGroup>
                        <Input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          id="floatingPassword"
                          name="password"
                          value={formik.values.password.trim()}
                          onChange={formik.handleChange}
                          placeholder="Password"
                        />
                        <BiLockAlt className="lock-icon" />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="toggle-password-visibility"
                          aria-label={showPassword ? "Hide password" : "Show password"}>
                          {showPassword ? <BiHide /> : <BiShow />}
                        </button>
                        {renderErrorMessage("password")}
                      </FormGroup>

                      <FormGroup>
                        <ReCAPTCHA
                          className="captch-resize"
                          ref={recaptchaInstance}
                          sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                          render="explicit"
                          onChange={verifyCallback}
                          onExpired={expireCallback}
                        />
                        {isFormSubmitted && !verified && <span style={{ color: "red", fontSize: "12px" }}>Please check the Captcha</span>}
                      </FormGroup>

                      <div className="form-group clearfix">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-theme"
                          disabled={loading ? true : false}>
                          {loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {loading && <span>Logging...</span>}
                          {!loading && <span>Login</span>}
                        </button>
                      </div>
                    </div>
                    <Row>
                      {openForgotPassword && (
                        <ForgetPassword
                          openForgotPassword={openForgotPassword}
                          setForgotPassword={setForgotPassword}
                        />
                      )}
                      <div className="forgetPassword">
                        Forgot Password &nbsp;
                        <Link
                          onClick={onForgotPasswordClick}
                          className="primary">
                          Click here
                        </Link>
                      </div>
                    </Row>
                  </Form>
                  <hr />
                  <Copyright className="mt-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
