import React from "react";
import AppConstant from "../../../constants/AppConstant";
export default function Copyright() {
  return (
    <>
      <div>
        <div>A product of</div>
        <p>
          ©&nbsp;{AppConstant.copyRightText} <br /> Pvt. Ltd. {new Date().getFullYear()}
        </p>
      </div>
    </>
  );
}
